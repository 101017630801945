<template>
  <section :class="classes"
           :style="background">
    <div class="content flex justify-start h-full ">
      <div class="flex flex-col py-16 md:justify-center h-full font-montserrat"
           :class="{'text-white': !dark, 'w-[40%]': textCentered}">
        <div v-if="withLogo" class="flex flex-col md:flex-row items-center">
          <LogoWhite class="rounded bg-primary" />
          <Pro class="m-10"/>
        </div>
        <h2 class="text-30 text-center md:text-34 md:mt-8 font-bold leading-[4rem] md:whitespace-pre-wrap"
            :class="{'md:text-left': !textCentered}"
            v-html="headline" />
        <div class="flex justify-center"
             :class="{'md:justify-start': !textCentered}">
          <InclinedText shadow size="lg" :label="catchphrase" />
        </div>
        <StoreButtons v-if="storeButtons" class="mt-16 content grid grid-cols-2"/>
      </div>
    </div>
  </section>
</template>

<script>
import LogoWhite from "assets/svg/logo-white-simple.svg?inline"
import Pro from "assets/svg/pro.svg?inline"
import InclinedText from "@/components/landing/InclinedText"
import StoreButtons from "@/components/buttons/StoreButtons"

export default {
  name: "BannerImage",
  components: {StoreButtons, InclinedText, LogoWhite, Pro },
  props: {
    headline: String,
    catchphrase: String,
    image: String,
    withLogo: Boolean,
    dark: Boolean,
    textCentered: Boolean,
    storeButtons: Boolean,
    fillColor: String,
    bgPosition: String,
    classes: {
      type: String,
      default: 'h-[58rem] md:h-[50rem] bg-cover bg-top md:bg-bottom'
    }
  },
  computed: {
    background() {
      return {
        background: this.fillColor,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${require('@/assets/img/' + this.image)})`,
        backgroundPosition: this.bgPosition
      }
    }
  }
}
</script>

<style scoped>
  .landing-cover {

  }
</style>
