<template>
  <validation-provider :customMessages="customMessages"
                       class="Field inline-block w-full relative flex flex-col"
                       :mode="validationMode"
                       tag="div" :name="name"
                       :rules="{...customRules,...confirmed && {'confirmed': confirmed},...regex && {'regex' : regex}, 'required' : required === true, ...max  && {max}, ...min  && {min},...max_value && {'max_value' : max_value},...min_value && {'min_value' : min_value}, email : type === 'email'}"
                       v-slot="{ errors }">
    <div class="Field relative">
      <div class="MaterialInput text-15 relative"
           :class="{
              'error' : [...errors, ...apiErrors].length,
              'success' : ![...errors, ...apiErrors].length && value,
              'opacity-50': disabled
           }">
        <span v-if="prefix" class="absolute pt-4 z-10 top-0 left-sm-x h-full flex items-center gap-4 font-bold">{{ prefix }}</span>
        <input
          spellcheck="false"
          :disabled="disabled"
          :value="value"
          :maxlength="max ? max : 999999"
          :autocomplete="autocomplete ? 'on' : (type === 'password' ? 'new-password' : 'off')"
          :pattern="pattern"
          class="MaterialInput__input focus:placeholder-opacity-0 w-full outline-none border border-gray-400 border-solid appearance-none placeholder-gray-600 bg-white"
          :placeholder="placeholder"
          :type="type"
          :name="name"
          :readonly="readonly"
          :class="{
            'pl-[4.5rem]': prefix,
            'text-18 text-center pt-0 font-bold': big,
            'border-report-error': [...errors, ...apiErrors].length,
            'bg-gray-200': disabled
          }"
          @input="$emit('input', $event.target.value)"
          @focus="$emit('focus', $event.target.value)"
          @focusout="$emit('focusout', $event.target.value)"
        />
        <label
          class="MaterialInput__label absolute pointer-events-none block left-sm-x whitespace-nowrap"
          :class="{'forceFocus': placeholder && placeholder !== ' ' || prefix || forceFocus}"
          v-html="label"/>
        <div class="absolute z-10 top-0 right-5 h-full flex justify-end gap-4">
          <slot name="icon"/>
          <span v-if="hint"
                class="h-full font-bold text-black-light items-center flex"
                :class="{'text-18': big}">{{ hint }}</span>
          <div v-if="[...errors, ...apiErrors].length"
               class="h-full flex items-center text-red-500"
               :class="{}">
            <Advice />
          </div>

        </div>

      </div>
      <span v-if="max > 0 && maxInfos" class="text-12 ml-auto"
            :class="{'text-red-500' : count === 0, 'text-black-light' : count > 0}">{{ $tc('input.charactersLeft', count) }}</span>
      <span v-if="infos" class="text-gray-medium pt-4 flex">
        <Advice class="w-9 flex-shrink-0" />
        <span class="text-12 pl-6" v-html="infos"/>
      </span>
      <Errors v-if="[...errors, ...apiErrors].length" :errors="[...errors, ...apiErrors]"/>
    </div>
  </validation-provider>
</template>

<script>
 import {ValidationProvider} from "vee-validate";
import Errors from "@/components/form/Errors"
import Advice from "~/assets/svg/advice.svg?inline";

export default {
  components: {ValidationProvider, Errors, Advice},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    apiErrors: {
      type: Array,
      default: () => []
    },
    customMessages: {
      type: String,
      default: null
    },
    infos: {
      type: String
    },
    customRules: {
      type: Object,
      default: () => {
      }
    },
    prefix: String,
    regex: RegExp,
    pattern: {
      type: String
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    max_value: {
      type: Number,
      default: null
    },
    min_value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    maxInfos: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'text'
    },
    confirmed: String,
    big: Boolean,
    hint: String,
    placeholder: {
      type: String,
      default: ' '
    },
    disabled: Boolean,
    forceFocus: Boolean,
    validationMode: {
      type: String,
      default: 'aggressive'
    }
  },
  computed: {
    count() {
      return Math.max(this.max - this.value.length, 0);
    }
  }
};
</script>
