<template>
  <nuxt-link :to="link" class="block w-full relative rounded-card overflow-hidden picture-hover">
    <Picture :src="picture" :sizes="[ '400:240' , '800:480']" />
    <div class="absolute inset-0 p-4 md:p-10 flex flex-col items-start justify-end">
      <h3 class="w-/5 text-18 md:text-30 text-white font-heading leading-8 md:leading-[3rem] font-black inline-block mb-2 md:mb-4 md:whitespace-pre-wrap" v-html="title" />
      <span  class="flex">
        <Icon icon="arrow-left" class="w-8 h-8 md:w-10 md:h-10 p-2 mr-4 rotate-180 text-white bg-primary rounded-full" />
        <span class="text-14 md:text-18 text-white underline">{{cta}}</span>
      </span>
    </div>
  </nuxt-link>
</template>

<script>

import Picture from "@/components/Picture"
import RoundedArrow from "~/assets/svg/roundedArrow.svg?inline";
import Icon from "@/components/global/Icon"

export default {
  components: {Icon, Picture, RoundedArrow},
  props: {
    link: String,
    cta: String,
    title: String,
    index: Number,
    picture: {
      type: String
    }
  }
}
</script>
