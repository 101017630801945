<template>
  <div class="loadmore-wrap">
    <div
      ref="track"
      class="refresh-track"
      :style="{
        transform: distance ? `translate3d(0, ${distance}px, 0)` : '',
        webkitTransform: distance ? `translate3d(0, ${distance}px, 0)` : '',
        transitionDuration: `${duration}ms`,
      }"
    >
      <div class="refresh-head" :style="headStyle">
        <Loading/>
      </div>

      <slot></slot>

      <div class="loadmore" ref="placeholder">

        <div class="loadmore-loading" v-if="loadLoading && !finished && !error">
          <Loading/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import { TouchMixin } from '@/mixins/loadmore/touch';
import { BindEventMixin } from '@/mixins/loadmore/bind-event';
import { TimeoutMixin } from '@/mixins/loadmore/timer';
// utils
import { preventDefault } from 'assets/js/utils/loadmore/event';
import { getScroller, getScrollTop } from 'assets/js/utils/loadmore/scroll';
import { throttle } from 'assets/js/utils/loadmore/throttle';
import Loading from "@/components/Loading";
// Icon
const TEXT_STATUS = ['pulling', 'loosing', 'refresh', 'success'];

export default {
  name: 'loadmore',

  mixins: [
    TouchMixin,
    BindEventMixin(function (bind) {
      if (!this.scroller) {
        this.scroller = getScroller(this.$el);
      }

      bind(this.scroller, 'scroll', throttle(this.checkSroll, 200));
    }),
    TimeoutMixin
  ],

  components: { Loading },

  props: {
    onRefresh: Function,
    pullDistance: {
      type: [Number, String],
      default: 50
    },
    headHeight: {
      type: [Number, String],
      default: 50
    },
    animationDuration: {
      type: [Number, String],
      default: 200
    },
    onLoadmore: Function,
    immediateCheck: {
      type: Boolean,
      default: false
    },
    loadOffset: {
      type: [Number, String],
      default: 50
    },
    finished: Boolean,
    error: Boolean,
  },

  data () {
    return {
      status: 'normal',
      distance: 0,
      duration: 0,
      scroller: null,
      loadLoading: false
    };
  },

  mounted () {
    this.bindTouchEvent(this.$refs.track);
    this.scroller = getScroller(this.$el);

    if (this.immediateCheck) {
      this.checkSroll();
    }
  },

  computed: {
    touchable () {
      return (
        this.status !== 'refresh' && this.status !== 'success' && this.onRefresh
      );
    },
    headStyle () {
      return this.headHeight !== 50 ? { height: `${this.headHeight}px` } : {};
    }
  },

  methods: {
    checkPullStart (event) {
      this.ceiling = getScrollTop(this.scroller) === 0;
      if (this.ceiling) {
        this.duration = 0;
        this.touchStart(event);
      }
    },

    onTouchStart (event) {
      if (!this.touchable) {
        return;
      }
      this.checkPullStart(event);
    },

    onTouchMove (event) {
      if (!this.touchable) {
        return;
      }

      if (!this.ceiling) {
        this.checkPullStart(event);
      }
      this.touchMove(event);

      if (this.ceiling && this.deltaY >= 0 && this.touchDirection === 'vertical') {
        preventDefault(event);

        this.setStatus(this.ease(this.deltaY));
      }
    },

    onTouchEnd () {
      if (this.deltaY && this.touchable) {
        this.duration = this.animationDuration;
        if (this.status === 'loosing') {
          this.showRefreshTip();

          // ensure value change can be watched
          this.$nextTick(() => {
            this.onRefresh(this.refreshDone);
          });
        } else {
          this.setStatus(0);
        }
      }
    },

    ease (distance) {
      const pullDistance = +(this.pullDistance || this.headHeight);

      if (distance > pullDistance) {
        if (distance < pullDistance * 2) {
          distance = pullDistance + (distance - pullDistance) / 2;
        } else {
          distance = pullDistance * 1.5 + (distance - pullDistance * 2) / 4;
        }
      }

      return Math.round(distance);
    },

    setStatus (distance, isRefresh = false) {
      let status;
      if (isRefresh) {
        status = 'refresh';
      } else if (distance === 0) {
        status = 'normal';
      } else {
        status = distance < (this.pullDistance || this.headHeight) ? 'pulling' : 'loosing';
      }

      this.distance = distance;
      if (status !== this.status) {
        this.status = status;
      }
    },

    refreshDone () {
      this.timeout(() => this.setStatus(0), 500);
    },

    showRefreshTip () {
      this.setStatus(+this.headHeight, true);
    },

    showSuccessTip () {
      this.status = 'success';
      this.timeout(() => this.setStatus(0), 1000);
    },

    checkSroll () {
      this.$nextTick(() => {
        if (this.loadLoading || !this.onLoadmore || this.finished || this.error) {
          return;
        }

        const { scroller, loadOffset } = this;
        let scrollerRect;

        if (scroller.getBoundingClientRect) {
          scrollerRect = scroller.getBoundingClientRect();
        } else {
          scrollerRect = {
            top: 0,
            bottom: scroller.innerHeight
          };
        }

        const scrollerHeight = scrollerRect.bottom - scrollerRect.top;

        if (!scrollerHeight) {
          return false;
        }

        const placeholderRect = this.$refs.placeholder.getBoundingClientRect();

        const bottomReached = Math.abs(placeholderRect.bottom - scrollerRect.bottom) <= loadOffset;

        if (bottomReached) {
          this.loadLoading = true;
          this.timeout(() => this.onLoadmore(this.loadmoreDone), 500);
        }
      });
    },

    loadmoreDone () {
      this.loadLoading = false;
    }

  }
};
</script>

<style scoped lang="scss">

// refresh
$refresh-head-height: 50px;

.loadmore-wrap {
  user-select: none;
}

.refresh {
  &-track {
    position: relative;
    height: 100%;
    transition-property: transform;
  }

  &-head {
    position: absolute;
    left: 0;
    width: 100%;
    height: $refresh-head-height;
    overflow: hidden;
    text-align: center;
    transform: translateY(-100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loadmore {
  height: 36px;
}
</style>
