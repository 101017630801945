<template>
  <nuxt-link :to="data.link"
             class="relative flex flex-col justify-center items-center w-full h-full rounded-card overflow-hidden overflow-webkit picture-hover">
    <Picture :sizes="['512:512']" :src="data.image" :alt="$t(data.label)"/>
    <span
      class="capitalize absolute bottom-0 text-center font-bold font-heading text-white text-18 w-full mt-3 py-8 bg-gradient-to-t from-gradient-medium"
      v-html="$t(data.label).toLowerCase()"/>
  </nuxt-link>
</template>

<script>
import Picture from "@/components/Picture"
export default {
  name: "Universe",
  components: {Picture},
  props: {
    data: {
      link: String,
      image: String,
      label: String,
    }
  }
}
</script>
