<template>
  <section class="flex flex-col gap-4 items-center bg-primary text-secondary pt-24 pb-20 text-center">
    <h2 class="text-30 underline" v-html="$t('newsletter.title')"/>
    <p class="text-14" v-html="$t('newsletter.subtitle')"/>
    <Form ref="form" @validated="checkForm" class="content flex justify-center text-left mt-12">
      <Input :autocomplete="false" required type="email"
             :label="$t('newsletter.email')" v-model="email" name="email"
             class="rounded-l-md overflow-webkit overflow-hidden max-w-[350px] -mr-4"/>
      <button class="p-sm-full text-14 rounded-input flex items-center justify-center font-medium leading-none h-[64px]  flex items-center justify-center rounded-l-none z-[1] text-16 font-bold min-w-[60px] bg-secondary text-white"
              :class="{'pointer-events-none' : success || loading }"
              type="submit">
        {{ $t('dialog.ok') }}
        <Loader v-if="loading"
                class="ml-4 animate-spin pointer-events-none w-10 h-10 w-full h-full transition-opacity"/>
      </button>
    </Form>
    <span v-if="success" class="text-white text-14">{{$t('newsletter.success')}}</span>
  </section>
</template>

<script>
import Input from "@/components/form/Input"
import Form from "@/components/form/Form"
import Loader from "~/assets/svg/loader.svg?inline";

export default {
  name: "Newsletter",
  components: {Input, Form, Loader},
  data: () => ({
    email: '',
    success: false,
    error: false,
    message: '',
    loading: false
  }),
  methods: {
    checkForm() {
      if (this.email.indexOf('@') > -1 && this.email.indexOf('.') > -1 && !this.loading) {
        this.send();
      }
    },
    async send() {
      this.$refs.form.reset()
      this.loading = true;
      this.error = null;
      const data = await this.$api.$post(process.env.apiUrl + '/api/v1/mailinglist', {
        email: this.email
      });
      if(data && !data.error) {
        this.success = true;
        this.email = '';
      }
      this.loading = false;
      /*try {
        if (data) {
          if (data.code === 200) {
            this.success = true
          } else {
            this.error = 'server';
            this.processing = false;
          }
          this.message = data.message
        } else {
          this.error = 'server';
          this.processing = false;
        }

      } catch (e) {
        this.processing = false;
        this.error = 'server'
        this.message = 'Nous rencontrons actuellement un problème, veuillez réessayer ultérieurement.'
        switch (e.response.code) {
          case 401 :
            this.error = 'captcha';
            break;
          default :
            this.error = 'server';
        }*/
    }
  }
}
</script>
