<template>
  <a @click="openLink"
     class="relative block w-full rounded-card overflow-hidden picture-hover w-[33rem] md:w-[40rem]"
     :class="(data?.color === 'white') ? 'text-white': 'text-black'">
    <div class="absolute top-0 left-0 z-[1] w-full h-full ">
      <Picture class="h-full" :src="data?.picture" :sizes="['750:475']" :resizing="'cover'" />
    </div>
    <div class="relative z-[2] h-[170px] w-3/4 px-7 pt-14 pb-6 flex flex-col justify-between">
      <div>
        <h3 class="font-heading font-bold text-24 leading-10" v-html="data?.title" />
        <p class="font-heading text-14 leading-6 mt-1" v-html="data?.text"/>
      </div>
      <div class="flex items-center">
        <span class="font-heading font-semibold text-14">{{data?.cta}}</span>
        <ArrowLongIcon class="w-7 h-7 ml-4" />
      </div>
    </div>
  </a>
</template>

<script>
import Picture from "@/components/Picture"
import ArrowLongIcon from "~/assets/svg/arrow-long.svg?inline";
import { Browser } from '@capacitor/browser';

export default {
  components: {
    Picture,
    ArrowLongIcon
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    isExternal() {
      const link = this.data?.link
      return link.indexOf('https') === 0 && link.indexOf('everide.app') === -1 && link.indexOf('secondo.app') === -1
    },
    url() {
      const link = this.data?.link
      if (this.isExternal) {
        return link
      } else {
        if (link.indexOf('everide.app') > -1 || link.indexOf('secondo.app') > -1) {
          return this.localePath({path: link.replace(/^.*\/\/[^\/]+/, '')})
        }
        if (this.localePath({ name: link }) !== '/') {
          return this.localePath({ name: link })
        } else {
          return this.localePath({path : '/' + link})
        }
      }
    }
  },
  methods: {
    async openLink() {
      if (this.isExternal) {
        await Browser.open({url: this.url});
      } else {
        await this.$router.push(this.url)
      }
    }
  }
}
</script>
