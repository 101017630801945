<template>
  <nuxt-link :to="localePath({path : '/' + data.link})" class="w-28 flex flex-col justify-center items-center">
    <div class="h-28 w-full">
      <Picture :sizes="['140:140']" :src="data.image" :alt="$t(data.label)" :no-bg="true" />
    </div>
    <span class="text-12 font-bold whitespace-nowrap" v-html="$t(data.labelShort)"/>
  </nuxt-link>
</template>

<script>
import Picture from "@/components/Picture"

export default {
  components: {
    Picture
  },
  props: {
    data: {
      link: String,
      image: String,
      label: String,
    }
  }
}
</script>
