<template>
  <section class="pl-4 sm:content mb-8 mt-12">

    <div class="mb-6 pl-2 pr-6 flex">
      <TextRow title-size="text-22 leading-normal"
               class="flex-1"
               description-size="text-14"
               title-tag="h2"
               :label="title"
               :description="description" />
      <nuxt-link v-if="allUrl"
                 :to="localePath({path: allUrl})"
                 class="font-heading flex font-semibold items-center text-14">{{ $t('see.all') }} <Icon class="ml-2 w-4" icon="arrow" /></nuxt-link>
    </div>

    <Swiper :mousewheel="$screen.desktop"
            :slides-per-view="'auto'"
            free-mode
            :navigation="!$screen.mobile">
      <!-- max-w-1/2 lg:max-w-[17.9%] -->
      <template v-slot="{readyHandler, initialized}">
        <SwiperSlide class="w-[48%] sm:w-[33%] lg:w-[23%] p-2 flex items-stretch md:mr-4"
                     v-for="(product, index) in products"
                     height-auto
                     @ready="readyHandler" :key="product.slug2 + '-' + index">

          <Product :product="product"
                   :gtm-item-list-name="gtmItemListName"
                   :index="index + 1"
                   @ready="readyHandler" />

        </SwiperSlide>
        <SwiperSlide v-if="allUrl" class="w-5/12 md:w-[33%] lg:w-[23%] p-2 flex items-stretch"
                     height-auto
                     @ready="readyHandler" :key="allUrl">

          <nuxt-link :to="localePath({path: allUrl})" class="flex items-center justify-center flex-col w-full relative h-auto rounded-card overflow-hidden bg-white shadow-product sm:hover:shadow-product-hover transition-shadow">
            <div class="flex flex-col justify-center items-center h-full px-10">
              <div class="text-15 font-bold flex flex-col items-center text-center mb-20">
                <div v-if="image" class="h-[80px] w-[80px] bg-white overflow-hidden">
                  <Picture class="w-[80px] h-[80px]"
                           :src="image"
                           :sizes="['160:160']"
                           :resizing="'fill'"
                           :no-bg="true"
                           :force-load="true" />
                </div>
                <span v-html="$t('Découvrir tous<br/>les produits')"/>
              </div>

              <span class="flex items-center underline text-14 font-medium">
                {{ $t('see.all') }} <Icon class="ml-2 w-4" icon="arrow" />
              </span>

            </div>
          </nuxt-link>


        </SwiperSlide>
      </template>
    </Swiper>
  </section>

</template>

<script>
import TextRow  from "@/components/global/TextRow.vue";
import Icon  from "@/components/global/Icon.vue";
import Swiper  from "@/components/Swiper.vue";
import SwiperSlide  from "@/components/SwiperSlide.vue";
import Product  from "@/components/Product.vue";
import Picture from "~/components/Picture.vue";

export default {
  name: "ProductSwiper",
  components: {Picture, Product, SwiperSlide, Swiper, Icon, TextRow},
  props: {
    title: String,
    description: String,
    image: String,
    gtmItemListName: String,
    products: Array,
    allUrl: String,
  }
}
</script>

<style scoped>

</style>
