export const refresh = {
  created() {
    this.$root.$on('pullToRefresh', this.onRefresh);
  },
  beforeDestroy() {
    this.$root.$off('pullToRefresh', this.onRefresh);
  },
  /*deactivated() {
    this.$root.$off('pullToRefresh', this.onRefresh);
  },
  activated() {
    this.$root.$on('pullToRefresh', this.onRefresh);
  },*/
  methods: {
    onRefresh() {
      if (!this._inactive && this.$fetch) {
        this.$fetch();
      }
    }
  }
}

export const layoutPop = {
   watch: {
     '$screen.current': function () {
       this.$nuxt.setLayout(this.$screen.mobile ? 'pop' : 'null');
     }
   },
   layout: ({$screen}) => {
     return $screen.mobile ? 'full' : null
   },
   created() {
     this.$nuxt.setLayout(this.$screen.mobile ? 'pop' : 'null');
   },
}
