<template>
  <div class="flex">
      <Icon icon="rating" v-for="i in nbStars"
                 :key="i" class="mr-2 fill-current"
                 :class="i <= rating ? 'text-primary' : 'text-surface-light'"
                 @click.native="onClickStar(i)"/>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon"

export default {
  components : {
    Icon,
  },
  props : {
    rating : {
      type : Number,
      default : 0
    },
    nbStars: {
      type: Number,
      default: 5
    },
    interactive: {
      type: Boolean,
      default: false
    },
    styleBig: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentRating: this.rating
    }
  },
  watch: {
    rating(newValue) {
      this.currentRating = newValue
    }
  },
  methods: {
    onClickStar(index) {
      if (!this.interactive) {
        return
      }

      this.currentRating = index
      this.$emit('rating', this.currentRating)
    }
  }
}
</script>
