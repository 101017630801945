<template>
  <nuxt-link :to="localePath({name : 'shop-pseudo', params : { pseudo : brand?.pseudo }})"
             class="block border rounded-card border-solid border-gray-400 shadow overflow-hidden h-auto w-full z-[2]">
    <div ref="banner" class="gradient-top relative w-full overflow-hidden h-[11rem] sm:h-[16rem] bg-surface-overlay flex items-center justify-center">
      <Picture
        ref="picture"
        :sizes="['800:600']"
        class="h-full"
        :key="brand?.id"
        :src="brand?.partnerHeaderImage"
        resizing="cover"/>
      <div class="z-[3] flex justify-between absolute top-4 left-4 right-4">
        <Badge :label="$t('label.brand')"
               type="sky-blue" />
        <FavoriteButton :type="2"
                        :id="brand?.id"
                        :data="brand"
                        no-padding
                        transparent
                        default-color="text-white"
                        :rounded="false" />

      </div>
      <div class="w-18 h-18 absolute bottom-4 left-4">
        <ProfilPic :src="brand?.image" class="w-18 h-18"/>
      </div>
    </div>
    <div class="bg-white flex flex-col px-5 py-2">
      <div class="overflow-x-hidden w-full">
        <span class="font-bold block truncate text-14 sm:text-16 capitalize">{{ brand?.fullName ? brand.fullName : brand?.pseudo }}</span>
        <span class="block truncate text-surface-medium font-medium text-12 sm:text-14">{{ $t('brand.card.description') }}</span>
      </div>
      <div class="flex flex-row text-11 sm:text-12 font-sans items-center">
        <Rating v-if="brand?.score > 0" class="text-primary w-5 h-5 mr-2"/>
        <span v-if="brand?.score > 0" class="mt-1 text-surface-medium">{{ brand?.score }}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import Rating from "~/assets/svg/rating.svg?inline"
import FavoriteButton from "@/components/buttons/FavoriteButton.vue"
import Picture from "@/components/Picture.vue"
import ProfilPic from "@/components/ProfilPic.vue"
import Badge from "@/components/global/Badge.vue"

export default {
  name: "BrandCard",
  components: {
    ProfilPic,
    FavoriteButton,
    Badge,
    Picture,
    Rating
  },
  props: {
    brand: Object
  },
}
</script>

<style scoped>

</style>
