<template>
  <div
    class="flex gap-5 bg-white rounded-card overflow-hidden overflow-webkit text-secondary text-14 w-full min-h-full shadow-lg p-6">
    <div class="w-[100px] flex-shrink-0">
      <Picture class="rounded-full rounded-tr-none overflow-hidden overflow-webkit" :src="data.picture"
               :sizes="['256:336']"/>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-surface-medium" v-html="data.author"/>
      <span class="font-bold" v-html="data.title"/>
      <span class="flex gap-3 mb-3 text-report-warning">
        <Rating :rating="data.stars" />
      </span>
      <span v-html="data.content"/>
    </div>
  </div>
</template>

<script>
import Rating from "@/components/Rating"
import Picture from "~/components/Picture.vue";

export default {
  name: "Advice",
  components: {
    Picture,
    Rating
  },
  props: {
    data: Object,
  },
  mounted() {
    this.$emit('ready')
  }
}
</script>

<style scoped>

</style>
