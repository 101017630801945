<template>
  <section class="flex flex-col gap-4 items-center text-secondary pt-24 pb-20">
    <h2 class="text-30 underline" v-html="$t('reassurance.title')"/>
    <p class="text-14" v-html="$t('reassurance.subtitle')"/>
    <div class="content flex justify-around gap-4 mt-10">
      <div class="flex flex-col items-center" v-for="item in guarantees">
        <client-only><Icon :icon="item.icon" class="text-primary h-[100px]"/></client-only>
        <span class="font-bold text-center text-13" v-html="$t(item.label)"/>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from "@/components/global/Icon"

export default {
  components : {
    Icon
  },
  computed: {
    guarantees() {
      return [
        {icon: 'payment', label: 'header.top.payment'},
        {icon: 'protection', label: 'header.top.protection'},
        {icon: 'delivery', label: 'header.top.delivery'},
        {icon: 'refund', label: 'header.top.refund'},
      ]
    },
  }
}
</script>

<style scoped>

</style>
