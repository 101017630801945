<template>
  <section class="pl-4 sm:content my-12">

    <TextRow title-size="text-22 leading-normal"
             class="mb-6 pl-2 pr-6"
             title-tag="h2"
             :label="title"
             :action="allUrl ? $t('see.all') : null"
             :action-arrow="!!allUrl"
             :description="description" />
    <Swiper :mousewheel="$screen.desktop"
            slides-per-view="auto">
      <!-- max-w-1/2 lg:max-w-[17.9%] -->
      <template v-slot="{readyHandler, initialized}">
        <SwiperSlide class="p-2 flex items-stretch w-3/4 md:w-5/12 lg:w-2/5"
                     v-for="(brand, index) in brands"
                     height-auto
                     @ready="readyHandler" :key="'brand-' + brand.id">

          <BrandCard :brand="brand" />

        </SwiperSlide>
      </template>
    </Swiper>
  </section>

</template>

<script>
import TextRow from "@/components/global/TextRow.vue"
import BrandCard from "@/components/brands/BrandCard.vue"
import Swiper from "@/components/Swiper.vue"
import SwiperSlide from "@/components/SwiperSlide.vue"

export default {
  name: "BrandSwiper",
  components: {
    SwiperSlide,
    Swiper,
    BrandCard,
    TextRow
  },
  props: {
    title: String,
    description: String,
    gtmItemListName: String,
    brands: Array,
    allUrl: String,
  }
}
</script>

<style scoped>

</style>
