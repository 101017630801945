<template>
  <IconButton :key="liked ? 'favorite' : 'favorite-stroke'"
              :icon="liked ? 'favorite' : 'favorite-stroke'"
              class="transition-colors duration-300"
              :class="{'text-primary': liked, [defaultColor]: defaultColor && !liked}"
              :no-padding="noPadding"
              :rounded="rounded"
              :border="border"
              :plain="!transparent" :small="small" :shadow="shadow"
              @click.native="onClick" />
</template>

<script>
import { getEcommerceItems, GTM_EVENTS } from "@/assets/js/gtm-events";
import IconButton from "@/components/buttons/IconButton"
import {FAVORITE_TYPES} from "assets/js/constants/constant";

export default {
  components: {
    IconButton,
  },
  props: {
    data: Object,
    id: String,
    shadow: Boolean,
    border: Boolean,
    type: {
      type: Number,
      default: FAVORITE_TYPES.product
    },
    transparent: Boolean,
    small: Boolean,
    noPadding: Boolean,
    rounded: {
      type: Boolean,
      default: true
    },
    defaultColor: String
  },
  data() {
    return {
      loading: false,
      animate: false,
      userAction: false,
    }
  },
  computed: {
    liked() {
      switch(this.type) {
        case FAVORITE_TYPES.user:
          return this.$auth.user && this.id && this.$auth.user.favoriteUser ? this.$auth.user.favoriteUser.includes(this.id) : false;
        case FAVORITE_TYPES.product:
          return this.$auth.user && this.id && this.$auth.user.favoriteProductIdList2 ? this.$auth.user.favoriteProductIdList2.includes(this.id) : false;
        default:
          throw 'Unsupported favorite type ' + this.type
      }

    },
  },
  watch: {
    liked(value, old) {
      if(this.userAction) {

        let fakeValue = 1;

        if(value === false) {
          fakeValue = 0;
        }

        this.$emit('fake',fakeValue);
      }
      this.animate = value === true && old === false && this.userAction;
    }
  },
  methods: {
    async onClick(e) {
      const self = this;
      e?.preventDefault()
      e?.stopPropagation()
      this.userAction = true;

      if (this.$auth.loggedIn) {
        if (!this.loading) {
          this.loading = true;
          let response;

          if (!this.liked) {
            response = await this.$api.$put(process.env.apiUrl + '/api/v2/favorites/add', {
              item_id: this.id,
              type: this.type
            });
          } else {
            response = await this.$api.$request( {
              url: process.env.apiUrl + '/api/v2/favorites/delete',
              method: 'delete',
              data: {
                item_id: this.id,
                type: this.type
              }
            });
          }

          if (response && !response.error) {
            await this.pushGtm()
            await this.$auth.fetchUser();
          }
          this.loading = false;
        }
      } else {
        if (this.$store?.state?.auth) {
          this.$store.state.auth.redirect = this.$route.path
        }
        await this.$store.dispatch('openForm', {
          width: 'regular',
          component: 'login',
          type: 'popinV2',
          callback: this.onClick.bind(this),
          data: {
            origin: 'product-like'
          }
        })
      }
    },
    async pushGtm() {
      switch(this.type) {
        case FAVORITE_TYPES.product:
          this.$gtm.push({
            event: GTM_EVENTS.item.like,
            ...getEcommerceItems([{
              id: this.id,
              name: this.data?.name,
              brand: this.data?.brand.name,
              categories: await this.$store.dispatch('nav/searchByID', this.data?.catalogId),
              price: this.data?.price,
              quantity: 1,
              itemState: this.data?.stateId,
              itemListName: null,
              index: null,
            }]),
          });
          break;
      }
    }
  }
}
</script>
