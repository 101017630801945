<template>
  <ValidationObserver tag="div" ref="form" slim v-slot="{valid, invalid, validate , handleSubmit, reset}">
    <form novalidate :autocomplete="autocomplete ? 'on' : 'off'" @submit.prevent="handleSubmit(onSubmit); showErrors()">
      <slot v-bind:valid="valid"/>
    </form>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver} from "vee-validate";

export default {
  components: {ValidationObserver},
  props: {
    fullWidth: Boolean,
    autocomplete: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    showErrors() {
      setTimeout(() => {
        if (this.$refs.form && this.$refs.form.errors) {
          const errors = Object.entries(this.$refs.form.errors)
            .map(([key, value]) => ({key, value}))
            .filter(error => error["value"].length)
            .sort((a, b) => {
              return this.$refs.form.refs[a["key"]].$el.getBoundingClientRect().top > this.$refs.form.refs[b["key"]].$el.getBoundingClientRect().top ? 1 : -1
            });
          if (errors && errors.length) {
            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
        }
      }, 200)
    },
    async onSubmit(e) {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {

        setTimeout(() => {
          this.showErrors();
        }, 200);

        return;
      }
      this.$emit('validated');

    }
  }
}
</script>
