<template>
  <section
    class="flex flex-col gap-4 items-center bg-primary-50 text-secondary pt-24 pb-16">
    <h2 class="text-30 underline" v-html="$t('advices.title')"/>
    <p class="text-14" v-html="$t('advices.subtitle')"/>
    <div class="content">
      <Swiper :fade="true" :mousewheel="true" :slides-per-view="1"
              :space-between="20" class="mt-12" fadeColor="from-primary-50"
              :breakpoints="{768: {slidesPerView : 1.8}, 1024: {slidesPerView : 2.4}, 1280: {slidesPerView : 3}}">
        <template slot-scope="{readyHandler,initialized}">
          <SwiperSlide :class="{'w-4/12' : !initialized}" @ready="readyHandler"
                       v-for="(advice, index) in advices" :key="index"
                       class="p-9 pt-0 h-auto">
            <Advice @ready="readyHandler" :data="advice"/>
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </section>
</template>

<script>
import Swiper from "@/components/Swiper"
import SwiperSlide from "@/components/SwiperSlide"
import Advice from "@/components/Advice"

export default {
  name: "Advices",
  components: {Advice, Swiper, SwiperSlide},
  data() {
    return {
      advices: [
        {
          picture: process.env.staticAssets + '/site/pages/ambassadors/xavier-thevenard.jpg',
          author: this.$t('feedbacks.1.author'),
          title: this.$t('feedbacks.1.title'),
          stars: 4,
          content: this.$t('feedbacks.1.desc')
        },
        {
          picture: process.env.staticAssets + '/site/pages/ambassadors/xavier-delerue.jpg',
          author:  this.$t('feedbacks.2.author'),
          title: 'Même pas peur',
          stars: 4,
          content: this.$t('feedbacks.2.desc')
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>
