var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{staticClass:"Field inline-block w-full relative flex flex-col",attrs:{"customMessages":_vm.customMessages,"mode":_vm.validationMode,"tag":"div","name":_vm.name,"rules":{..._vm.customRules,..._vm.confirmed && {'confirmed': _vm.confirmed},..._vm.regex && {'regex' : _vm.regex}, 'required' : _vm.required === true, ..._vm.max  && {max: _vm.max}, ..._vm.min  && {min: _vm.min},..._vm.max_value && {'max_value' : _vm.max_value},..._vm.min_value && {'min_value' : _vm.min_value}, email : _vm.type === 'email'}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"Field relative"},[_c('div',{staticClass:"MaterialInput text-15 relative",class:{
            'error' : [...errors, ..._vm.apiErrors].length,
            'success' : ![...errors, ..._vm.apiErrors].length && _vm.value,
            'opacity-50': _vm.disabled
         }},[(_vm.prefix)?_c('span',{staticClass:"absolute pt-4 z-10 top-0 left-sm-x h-full flex items-center gap-4 font-bold"},[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"MaterialInput__input focus:placeholder-opacity-0 w-full outline-none border border-gray-400 border-solid appearance-none placeholder-gray-600 bg-white",class:{
          'pl-[4.5rem]': _vm.prefix,
          'text-18 text-center pt-0 font-bold': _vm.big,
          'border-report-error': [...errors, ..._vm.apiErrors].length,
          'bg-gray-200': _vm.disabled
        },attrs:{"spellcheck":"false","disabled":_vm.disabled,"maxlength":_vm.max ? _vm.max : 999999,"autocomplete":_vm.autocomplete ? 'on' : (_vm.type === 'password' ? 'new-password' : 'off'),"pattern":_vm.pattern,"placeholder":_vm.placeholder,"type":_vm.type,"name":_vm.name,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":function($event){return _vm.$emit('focus', $event.target.value)},"focusout":function($event){return _vm.$emit('focusout', $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"MaterialInput__label absolute pointer-events-none block left-sm-x whitespace-nowrap",class:{'forceFocus': _vm.placeholder && _vm.placeholder !== ' ' || _vm.prefix || _vm.forceFocus},domProps:{"innerHTML":_vm._s(_vm.label)}}),_vm._v(" "),_c('div',{staticClass:"absolute z-10 top-0 right-5 h-full flex justify-end gap-4"},[_vm._t("icon"),_vm._v(" "),(_vm.hint)?_c('span',{staticClass:"h-full font-bold text-black-light items-center flex",class:{'text-18': _vm.big}},[_vm._v(_vm._s(_vm.hint))]):_vm._e(),_vm._v(" "),([...errors, ..._vm.apiErrors].length)?_c('div',{staticClass:"h-full flex items-center text-red-500",class:{}},[_c('Advice')],1):_vm._e()],2)]),_vm._v(" "),(_vm.max > 0 && _vm.maxInfos)?_c('span',{staticClass:"text-12 ml-auto",class:{'text-red-500' : _vm.count === 0, 'text-black-light' : _vm.count > 0}},[_vm._v(_vm._s(_vm.$tc('input.charactersLeft', _vm.count)))]):_vm._e(),_vm._v(" "),(_vm.infos)?_c('span',{staticClass:"text-gray-medium pt-4 flex"},[_c('Advice',{staticClass:"w-9 flex-shrink-0"}),_vm._v(" "),_c('span',{staticClass:"text-12 pl-6",domProps:{"innerHTML":_vm._s(_vm.infos)}})],1):_vm._e(),_vm._v(" "),([...errors, ..._vm.apiErrors].length)?_c('Errors',{attrs:{"errors":[...errors, ..._vm.apiErrors]}}):_vm._e()],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }