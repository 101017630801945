<template>
  <header class="e-header-light z-20 w-full bg-white" :class="{'top-nav sticky top-sticky' : topNav}">
    <TopNav class="block" :class="{'hidden' : !topNav}"/>
    <div class="lg:h-auto pt-4 pb-4 flex items-center content">
      <Back :force-home="forceHome" class="mr-4 lg:hidden" :class="{'hidden' : !hasBack}" :big="true"/>
      <div v-if="hasBackEmitter" class="w-22 h-22 mr-4 flex justify-center items-center rounded-full bg-white border border-gray-400 shadow-btn cursor-pointer" @click="onClickBackEmitter">
        <ArrowLeftIcon class="w-6 h-6"/>
      </div>

      <SearchInput v-if="!searchRedirect" :autoComplete="searchAutoComplete" :type="searchType"/>

    </div>
  </header>
</template>

<script>
import TopNav from "@/components/header/TopNav"
import Back from "@/components/buttons/Back"
import SearchInput from "@/components/SearchInput"
// Icons
import SearchIcon from "~/assets/svg/search.svg?inline";
import ArrowLeftIcon from "~/assets/svg/arrow-left.svg?inline";

export default {
  components: {
    TopNav,
    SearchInput,
    Back,
    SearchIcon,
    ArrowLeftIcon
  },
  props: {
    forceHome: {
      type: Boolean,
      default: false
    },
    topNav: {
      type: Boolean,
      default: false
    },
    hasBack: {
      type: Boolean,
      default: false
    },
    hasBackEmitter: {
      type: Boolean,
      default: false
    },
    searchRedirect: {
      type: Boolean,
      default: false
    },
    searchType: {
      type: Number,
      default: 1
    },
    searchAutoComplete: {
      type: Boolean,
      default: true
    },
    onClickBack: Function
  },
  data: () => ({
    count: 0
  }),
  mounted() {
    this.count = document.getElementsByTagName('*').length;
  },
  methods: {
    onClickBackEmitter() {
      this.onClickBack()
    }
  }
}
</script>
