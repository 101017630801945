<template>
  <div v-if="!isApp" class="flex gap-4">
    <a rel="noreferrer"
       href="https://apps.apple.com/fr/app/everide/id1587901394"
       target="_blank"
       class="flex justify-end">
      <img width="108" height="33" alt="App Store - Apple"
           :src="appStore"/>
    </a>
    <a rel="noreferrer"
       href="https://play.google.com/store/apps/details?id=com.everide.app&gl=FR"
       target="_blank">
      <img width="108" height="33" alt="Google play store"
           :src="playStore"/>
    </a>
  </div>
</template>

<script>
export default {
  name: "StoreButtons",
  data: () => {
    return {
      playStore: process.env.staticAssets + '/site/playstore.svg',
      appStore: process.env.staticAssets + '/site/appstore.svg',
    }
  },
  computed: {
    isApp() {
      return process.env.mobileApp;
    },
  }
}
</script>

<style scoped>

</style>
